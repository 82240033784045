import { getAllScreeners } from "@/app/api/screeners";
import { useUserContext } from "@/context/user.context";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { AllScreenersResponse } from "@/types/screeners";
import type { DefaultUseQueryConfigParam } from "@/types/third-party";

const SCREENER_QUERY_KEY_BASE = "screener";
export const SCREENER_QUERY_KEYS = {
  base: [SCREENER_QUERY_KEY_BASE] as const,
};

export const useAllScreenersQuery = (
  config: DefaultUseQueryConfigParam<AllScreenersResponse> = {},
) => {
  const { user } = useUserContext();

  return useAuthedQuery({
    queryKey: SCREENER_QUERY_KEYS.base,
    queryFn: getAllScreeners,
    select: ({ columns, screenerRows }) => ({
      columns,
      // User will definitely be defined here, as the `useAuthedQuery` hook
      // relies on the user.
      screenerRows: user?.internal
        ? screenerRows
        : screenerRows.filter(({ isDraft }) => !isDraft),
    }),
    ...config,
  });
};
